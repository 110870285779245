$euiColorPrimary: #36786a;
$euiSize: 8px;

@import "@elastic/eui/src/themes/amsterdam/colors_light";
@import "@elastic/eui/src/themes/amsterdam/globals";

@import "~video-react/styles/scss/video-react";

@import "@elastic/charts/dist/theme_only_light.css";

.euiButton[class*="fill-accent"] {
  color: #fff !important;
}

.euiPageBody {
  // @include euiHeaderAffordForFixed;
  background-color: #fff;
  margin-top: 5px;
}

.euiPageTemplate {
  min-block-size: max(460px, calc(100vh - 3px)) !important;
}

.euiPage {
  margin-top: -6px;
}

.euiHeader {
  box-shadow: none !important;
  border-block-end: none !important;
  border-bottom: none;
}

.euiPage > [class*="euiPageSidebar"] {
  min-inline-size: 55px !important;
  position: relative;
  left: 4px;
}

.euiSideNavItemButton__label {
  font-size: 0px;
}
.euiBody--headerIsFixed .euiCollapsibleNav {
  padding-top: 48px;
  z-index: 900;
}
.euiOverlayMask {
  // @include euiHeaderAffordForFixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
html,
body,
#root,
.euiPageTemplate {
  background-color: #d1dfdf !important;
}
.euiTableRow.ignore {
  background-color: rgba(#9c9c9c, 0.2);
}

.euiTableRow.ok {
  background-color: rgba(#36786a, 0.2);
}

.euiTableRow.warning {
  background-color: rgba(#c97836, 0.2);
}

.euiTableRow.error {
  background-color: rgba(#e83636, 0.2);
}

.euiTableRow[data-selected="true"] {
  background-color: rgba(#36786a, 0.3) !important;
}

.euiSideNavItemButton.active {
  color: #36786a;
  font-weight: bold;
  user-select: none;
}

.euiText ul {
  margin-block-end: 0 !important;
}

.euiHeaderBreadcrumbs .euiBreadcrumb__content {
  color: #404040;
}

input[readonly],
textarea[readonly] {
  color: rgba(#2c6463, 0.7) !important;
}
//turning this of for now, chek is will become issue
[class*="euiFlexItem-grow"] > div:not(.euiBasicTable) > div:not(.na-text):not(.euiSpacer):not(.euiText) {
  // height: 100%;
}

#save-select > div {
  height: 100%;
}

.order-shortlists .euiBasicTable {
  border-top: solid 1px #d3dae6;
}
/** for the legacy order input - hide the up/down arrow buttons for number input **/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

.images-holder {
  display: flex;
  flex-wrap: wrap;
  & > div {
    gap: 2;
    width: 50%;
    padding: 2.5px;
  }
}
.secure-image-holder {
  figure {
    position: relative;

    figcaption {
      position: absolute;
      z-index: 1;
      bottom: 0px;
      right: 0px;
      font-size: 9px;
      padding: 1px 5px;
      font-feature-settings: "tnum";
      color: #333;
      background-color: rgba(#ddd, 0.7);
    }
  }
}

// [data-test-subj="superDatePickerNowTab"]#now {
//     display:none;
// }

[data-test-subj="superDatePickerQuickMenu"] .euiQuickSelectPopover__section {
  max-height: 155px;
}

.euiComboBox.legacy-ui .euiComboBox__inputWrap,
.euiSuperSelectControl.legacy-ui {
  background-color: transparent;
  background-image: none;
  &:focus {
    background-image: none;
  }
  &[disabled] {
    opacity: 0.25;
  }
}

.euiSuperSelectControl-isInvalid.legacy-ui {
  background-image: none;
  border: solid 1px #bd271e;

  & + div [data-icon-type="alert"] {
    display: none !important;
  }
}

.checkmark-list-holder ul li,
.checkmark-list-holder ol li {
  margin-left: 23px;
  padding-left: 2px;
  list-style-type: disc;
}

.checkbox-holder label {
  font-size: 16px !important;
  font-weight: bold;
}

.scan-position-overlay button,
#scan-position-overlay .euiImageFullScreenWrapper button {
  position: relative;

  &::after {
    position: absolute;

    top: 15%;
    right: 15%;
    left: 15%;
    bottom: 15%;
    border: solid 1px rgba(#36786a, 0.3);
    z-index: 10;
    content: "";
    pointer-events: none;
  }
}

.euiPageHeaderContent__top .euiFlexItem {
  flex-direction: row;
}

.euiTableCellContent__hoverItem > *:not(.euiPopover) .action-text {
  display: none;
}

.euiFlyout.processing-run {
  overflow-y: scroll;
}

.euiFormControlLayout.confirm-select-list-control > *:last-child {
  width: 130px;

  > .euiFormControlLayout__childrenWrapper {
    position: relative;
    top: -1px;
    left: -1px;
  }
}

.euiHeaderProfile {
  padding: 0px;
  & > *:first-child {
    width: 80px;
  }
  &:last-child {
    height: 80px;
  }
}

.scan-table {
  background-color: transparent;
  & > * {
    background-color: transparent;
  }
  & > * > * {
    background-color: transparent;
  }
}
.mm-reloadable {
  transition: background-color 0.7s ease-in-out;
  & > * {
    transition: background-color 0.7s ease-in-out;
  }
  & > * > * {
    transition: background-color 0.7s ease-in-out;
  }

  &.mm-reloaded {
    background-color: #bed6d7;
    & > * {
      background-color: #bed6d7;
    }
    & > * > * {
      background-color: #bed6d7;
    }
  }
}

.demo-banner {
  position: fixed;
  transform: rotate(45deg);
  top: 61px;
  right: -30px;
  z-index: 10;
  padding: 2px 40px !important;
}

.video-react {
  background-color: #fff;
  .video-react-poster {
    background-color: #fff;
  }
  .video-react-big-play-button-center {
    font-size: 2em;
  }
}

.euiSideNavItem--root > .euiSideNavItemButton {
  margin-bottom: 24px;
}

.euiTreeView__node {
  & > button.active {
    color: #36786a;
  }
}

#permissions-grid {
  position: relative;
  top: -100px;
  td {
    padding: 4px;
    .no-check {
      display: block;
      width: 16px;
      height: 16px;
      background-color: #fcfcfc;
      border-radius: 3px;
    }
  }
  th {
    text-align: left;
    vertical-align: bottom;
    height: 160px;
    &.policy-name {
      position: relative;
      width: 25px;
      span {
        left: 7.5px;
        position: absolute;
        z-index: 4;
        transform: rotate(-45deg);
        transform-origin: bottom left;
        overflow: visible;
        white-space: nowrap;
        bottom: 5px;
      }
    }
    &:last-child {
      width: 40px;
    }
  }
  td {
    vertical-align: bottom;
  }

  tr.group > td {
    background-color: #333;
    color: #fff;
    z-index: 1;
  }
  tr.sub-group > td {
    background-color: #ccc;
  }

  border-spacing: 0;
  border-collapse: collapse;
  overflow: hidden;
  td,
  th {
    position: relative;
  }

  tbody tr.highlight:hover {
    background-color: rgba(#36786a, 0.1);
  }

  tbody td:not(.policy-group) + td.policy-group {
    border-left: solid 1px #ccc;
  }

  tbody td.policy-group + td.policy-role {
    border-left: solid 1px #ccc;
  }
  tbody td.policy-group {
    background-color: #efefef;
  }

  tbody td.highlight:hover::after {
    background-color: rgba(#36786a, 0.1);
    content: "\00a0";
    height: 10000px;
    left: 0;
    position: absolute;
    top: -5000px;
    width: 100%;
  }
}

.paste-and-drag-location {
  & * {
    // user-select: none;
    pointer-events: none;
  }
  &:focus {
    background-color: rgba(#36786a, 0.25);
  }
}

.workflow-step-indicators {
  gap: 2.25px !important;

  & > .workflow-step-indicator {
    flex-basis: 12px;
    flex-grow: 0;
    flex-shrink: 0;
    height: 12px;
    border: solid 1px #9c9c9c;
    background-color: #efefef;
    &.checked {
      background-color: #36786a;
      border: solid 1px #36786a;
    }
    &.unchecked {
      background-color: #bd271e;
      border: solid 1px #bd271e;
    }
  }
}

.order-header {
  position: relative;
  background-color: #8daeae;
  padding-bottom: 0px !important;
  border-radius: 10px 10px 0px 0px;
  .euiText:not([class*="danger"]) {
    color: #ffffff;
  }
  .order-tabs {
    align-items: center;
    justify-content: center;
    width: 100%;
    .euiTab {
      margin-left: 40px;
      margin-right: 40px;
      margin-bottom: 5px;

      @include euiBreakpoint("l") {
        margin-left: 20px;
        margin-right: 20px;
      }

      @include euiBreakpoint("xs", "s", "m") {
        margin-left: 5px;
        margin-right: 5px;
      }
      &.euiTab-isSelected,
      &:hover {
        box-shadow: inset 0 -3px 0 #ffffff;
      }
      .euiText:not([class*="danger"]) {
        color: #ffffff;
      }
    }
    .euiTab:first-child {
      margin-left: 0px;
    }
    .euiTab:last-child {
      margin-right: 0px;
    }
    &.order-tabs-tight .euiTab {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  .more-buttons {
    position: absolute;
    right: 12.5px;
    top: 7.5px;
    cursor: pointer;
  }
}

.order-entry.order-focus-delivery .order-header {
  background-color: #8198ac;
}

.order-cards {
  width: 200px;
}
.order-cards-holder {
  overflow-y: auto;
  max-height: calc(100vh - 484px);
  overflow-x: hidden;
  padding-bottom: 10px;
  margin-bottom: 10px;
  margin-left: -20px;
  margin-right: -20px;
}
.order-entry:not(.order-focus-client, .order-focus-loading) .order-entry-input {
  background-color: #8daeae;
  border-radius: 10px 10px;
}

.order-entry.order-focus-delivery .order-entry-input {
  border-radius: 10px 10px;
  background-color: #8198ac;
}

.order-details {
  background-color: #eeeeee;
  border-radius: 10px 10px;
  .product-select-holder {
    max-height: calc(100vh - 450px);
  }
  & > .euiFlexGroup {
    height: calc(100vh - 393px);
  }
  .button-transparent {
    background-color: transparent;
    border: solid 0.5px #7e7e7e;
  }
}

.paste-and-drag-location {
  width: 210px;
  border: dashed 1px #7e7e7e88;
  background-color: transparent !important;
  box-shadow: none !important;
  transform: none;

  &:hover {
    transform: none !important;
    background-color: #ffffff44 !important;
  }
}
.order-card {
  .euiPopover {
    width: 100%;
    .euiPopover__anchor {
      width: 100%;
    }
  }
}
.order-card .product-card {
  margin: 0px 12.5px;
  padding: 12.5px 12.5px;
  position: relative;
  left: 0px;
  border-radius: 10px;
  width: calc(100% + 5px);
  .select-option-holder {
    display: none;
    position: absolute;
  }
  &:hover {
    background-color: transparent;
    .select-option-holder {
      display: flex;
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;
      border-radius: 10px;
      background-color: rgba(#1a1c21, 0.5);
      z-index: 10;

      align-items: center;
      .select-option-main {
        position: absolute;
        flex-grow: 10;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0.75;
        &:hover {
          opacity: 1;
        }
      }

      .select-option-sub {
        position: absolute;
        right: 0px;
        top: 0px;
        bottom: 0px;
        cursor: pointer;
        opacity: 0.75;
        padding: 12.5px 12.5px 25px 25px;
        &:hover {
          opacity: 1;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          background-color: rgba(#1a1c21, 0.25);
        }
      }
    }
  }
}

.product-card {
  cursor: pointer;
  &:hover {
    background-color: none;
  }
  &.product-active {
    background-color: rgba(#1a1c21, 0.15);
  }
}

.product-select button {
  height: 100%;
}

.measurement-card {
  min-width: 300px !important;
  box-shadow: none !important;
}

.product-select,
.product-card {
  .euiImageWrapper {
    height: 100%;
  }
  .euiImage.image {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.euiTitle.subdued {
  font-weight: 400 !important;
  color: #c5c5c5 !important;
}

.order-completion-holder {
  background-color: #eeeeee;
  // background-color: rgb(209, 223, 223);
  // &.is-complete {
  //   background-color: #8daeae;
  // }
  // &.is-incomplete {
  //   background-color: #a96767;
  // }

  border-radius: 10px 10px 0px 0px;
  padding: 40px 25px 25px 25px;
  .order-completion {
    cursor: pointer;
  }
  height: 235px;
}

.order-entries {
  border-radius: 10px;
  margin-top: 158px;

  .order-header {
    background-color: #ffffff;
    border-radius: 0px;
  }
}

.order-completion-holder + .order-entries {
  margin-top: -10px;
  // border-radius: 0px 0px 10px 10px;
  position: relative;
}

.mobile-row .euiFlexItem {
  inline-size: auto !important;
  flex-basis: 0 !important;
  flex-grow: 0;
}

.mobile-row > .euiFlexItem,
.mobile-row .space-below {
  margin-bottom: 1em;
}

.order-details {
  text-decoration: none !important;
}

.euiFilterButton-hasActiveFilters {
  font-weight: normal;
  & > span {
    background-color: #8daeae;
    color: #ffffff;
  }
  &.succes > span {
    background-color: #0a755e;
  }
  &.danger > span {
    background-color: #af0e0e;
  }
}
.euiFilterButton {
  padding-left: 0px;
  padding-right: 0px;
  padding-inline: 0px !important;
}
.euiFilterButton__text {
  padding-left: 7.5px;
  padding-right: 7.5px;
}
.euiFilterButton__textShift,
input,
.euiFilterButton__text,
.euiSuperSelectControl {
  font-size: 13px !important;
  text-decoration: none !important;
}

.product-input {
  .euiComboBox.option-selected .euiComboBox__inputWrap,
  input.has-input:not(:focus),
  textarea.has-input,
  .euiSuperSelectControl.option-selected {
    background-color: #ffffff;
    font-weight: 500;
    color: #000;

    &:has(> input[disabled]) {
      background-color: inherit;
    }
  }

  .euiFilterButton-hasActiveFilters {
    font-weight: normal;
    & > span {
      background-color: #8daeae;
      color: #ffffff;
      font-weight: 500;
    }
  }
  .euiFilterButton__textShift,
  input,
  .euiSuperSelectControl {
    font-size: 13px !important;
    text-decoration: none !important;
  }
}

[data-euiportal] .euiPanel {
  width: auto !important;

  input[type="range" i] {
    color: none;
  }
}

.mm-tooltip {
  ol,
  ul {
    margin: 0px 0px 0px 15px;
  }
  ul li {
    list-style-type: disc;
  }
  ol li {
    list-style-type: decimal;
  }
}

.hoverable {
  cursor: pointer;

  .hoverable-highlight {
    opacity: 0 !important;
  }

  &:hover {
    .hoverable-highlight {
      opacity: 0.5 !important;
    }
  }
}

.euiTab__content {
  line-height: 1.4286rem !important;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.euiToolTipAnchor {
  display: inline !important;
}

.client-focus-holder {
  width: 300px;
  height: 0px;
  justify-self: center;
}

.euiPopover__panel:has(> div > .client-focus-client) .euiPopover__arrow,
.euiPopover__panel:has(> div > .client-focus-client) .euiPopover__arrow {
  display: none;
}

.euiOverlayMask:has(.order-client-modal) {
  background-color: transparent;
}
.euiModal.order-client-modal {
  min-inline-size: 120px;
  background-color: #ffffff;
  .euiButtonIcon.euiModal__closeIcon {
    display: none;
  }
  .euiModalBody {
    padding-top: 16px;
  }
}
.euiModal.order-modal {
  box-shadow: none;
  .euiModal__closeIcon:has(+ .order-focus-client),
  .euiModal__closeIcon:has(+ .order-focus-loading) {
    display: none;
  }

  max-width: 1400px;
  height: calc(100vh - 180px) !important;
  max-height: calc(100vh - 180px);
  width: calc(100vw - 50px);

  .order-focus-loading {
    justify-content: center;
    align-items: center;
    max-width: 1400px;
    width: 100vw;
  }

  .order-focus-client .order-header,
  .order-focus-loading .order-header {
    opacity: 0;
  }
  &:has(> .order-focus-client) {
    width: 0px !important;
    height: calc(100vh - 440px) !important;
  }

  &:has(> .order-focus-order) {
    transition-property: width, height;
    transition-duration: 0.2s, 0.3s;
    transition-timing-function: ease-in-out;
  }

  top: 50px;
  padding-left: 25px;
  background-color: transparent;
  border-radius: 0px;
  box-shadow: none;
  padding-right: 25px;

  .euiModal__closeIcon {
    background-color: transparent;
    color: #fff;
    font-size: 20px;
    right: -4px;
    top: 10px;
  }
  // &:has(.order-entry.has-order-lines) {
  //   .euiModal__closeIcon {
  //     right: calc(190px + 65px);
  //   }
  // }
}

.euiFormRow.readonly-input .euiFormRow__fieldWrapper {
  border-bottom: solid 1px rgba(#c97836, 0.22);
}
.euiModal__closeIcon {
  background-color: transparent;
}

.euiModal:focus,
[data-focus-guard]:focus {
  outline: none !important;
}
.order-card .product-card-appearing {
  animation: emphasize 2s ease-in-out forwards;
}
@keyframes emphasize {
  0% {
    background-color: rgba(#36786a, 0);
  }
  5% {
    background-color: rgba(#36786a, 0);
  }
  6% {
    background-color: rgba(#36786a, 0.2);
  }
  100% {
    background-color: rgba(#36786a, 0);
  }
}

span.thin {
  font-weight: 300;
  font-size: 0.8rem;
  display: block;
}

.demo-bar {
  &::after {
    content: "";
    background-color: #7f2626 !important;
    position: fixed;
    height: 5px;
    top: 0px;
    width: 100%;
  }
  position: fixed;
  z-index: 100000 !important;
  height: 0px;
  pointer-events: none;
  .euiText {
    background-color: #7f2626 !important;
    padding: 3px 10px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 2px 2px 4px rgba(#000000, 0.25);
  }
}

.euiFormLabel,
.euiFormLabel-isFocused {
  color: #9c9c9c !important;
  font-weight: 400 !important;
}

.digit-selection-subdued {
  opacity: 0.5;
  background-color: #f3f3f3;
}
.scan-steps {
  position: relative;
  ol {
    counter-reset: item;
    position: relative;
    &:before {
      position: absolute;
      content: "";
      height: 100%;
      border-left: dotted 1px #85a3a2;
      left: -6px;
    }
    li {
      counter-increment: item;

      font-weight: 600;
      position: relative;
      margin: 40px 0px;
      padding-left: 40px;

      &:last-child {
        margin-bottom: 0px;
      }
      &:before {
        content: "";
        background-color: #85a3a2;
        width: 28px;
        height: 28px;
        left: -19px;
        top: -2px;
        display: block;
        position: absolute;
        border-radius: 28px;
        z-index: -1;
      }
      &::marker {
        color: #fff;
        content: counter(item);
      }
    }
  }
}

.euiBody-hasPortalContent:has(.referrer-options.euiComboBox-isOpen) > div[data-euiportal="true"]:last-child .euiPanel,
.euiBody-hasPortalContent:has(.referrer-options.euiComboBox-isOpen)
  > div[data-euiportal="true"]:last-child
  .euiPanel
  .euiComboBoxOptionsList__rowWrap
  > div {
  width: 400px !important;
}

.referrer-options {
  max-width: 200px !important;
}
.order-content:not(.order-product-selection) {
  max-height: calc(100vh - 440px);
  overflow-y: auto;
}
// .order-details-buttons {
//   padding-bottom: 20px;
// }

.euiHeaderLinks .euiHeaderLinks__list a:first-child .euiHeaderSectionItemButton {
  // border: solid 1px #f00;
  position: relative;
  left: 5px;
}
.euiBasicTable.rows-paper {
  th,
  td {
    border: none;
  }
  td {
    border-top: solid 0.5px #efefef;
    border-collapse: collapse;
  }
  th .euiTableCellContent__text {
    color: #c5c5c5;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 400;
    font-size: 1rem;
  }
  tr:hover {
    background-color: inherit;
  }
  [style*="pointer"]:hover .euiText {
    color: #2c6463;
  }
  td .euiFormControlLayout {
    background-color: transparent;
    border: none;
    box-shadow: none;
    .euiFormLabel {
      border: none;
      &.euiFormControlLayout__prepend,
      &.euiFormControlLayout__append {
        background-color: transparent;
      }
    }
    input {
      border: solid 1px rgba(17, 43, 134, 0.1);
      border-radius: 3px;
    }
  }
}
.euiBasicTable.rows-free {
  background-color: transparent;

  table {
    border-spacing: 0em 1em;
    border-collapse: separate;
    position: relative;
    top: -1em !important;
  }
  table,
  thead {
    background-color: transparent;
    position: relative;
    top: 1em;

    tr > th {
      padding-left: 5px;
      padding-right: 5px;
      span {
        color: #c5c5c5;
        font-weight: 400 !important;
        font-size: 1em;
      }
      &:first-child {
        padding-left: 12.5px;
      }
    }
  }
  tbody > tr.euiTableRow {
    background-color: #ffffff;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  tBody > tr.euiTableRow > td {
    border: none !important;
    padding-left: 5px;
    padding-right: 5px;
    &:first-child {
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      padding-left: 12.5px;
    }
    &:last-child {
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      padding-right: 12.5px;
    }
  }
}

.who-where-location,
.who-where-practitioner {
  text-align: right;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hoverable-highlight {
  position: absolute;
  right: -15px;
}

.notification-warning {
  background-color: #d71212 !important;
}

.table-transparent table.euiTable {
  background-color: transparent;
}
.table-no-header table.euiTable {
  thead {
    height: 0px;
    .euiTableCellContent {
      padding: 0px;
    }
  }
}

.referral-status-select-item {
  width: 220px;
}
.scan-status-select-item {
  min-width: 220px;
}
.euiPanel:has(.delivery-details) {
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.euiFormControlLayout:has(.fake-prepend) {
  .euiFormLabel.euiFormControlLayout__prepend {
    border: none !important;
    overflow: visible !important;
    background-color: #fbfcfd !important;
    font-weight: 400;
    font-size: 13px !important;
    padding-left: 8px;
    color: #343741cc !important; // #69717d;
    position: absolute;
    border-radius: 0px;
    z-index: 10;
    height: calc(100% - 4px);
    pointer-events: none;
  }

  &.euiFormControlLayout-isDisabled .euiFormLabel.euiFormControlLayout__prepend {
    background-color: #eef2f7 !important;
    color: #34374177 !important; // #69717d;
  }

  &.euiFormControlLayout--compressed.euiFormControlLayout--group {
    background-color: #fbfcfd !important;
  }
}

.euiFormControlLayout:has(.fake-prepend.euiSuperSelect--isOpen__button) {
  .euiFormLabel.euiFormControlLayout__prepend {
    background-color: #ffffff !important;
  }
  &.euiFormControlLayout--compressed.euiFormControlLayout--group {
    background-color: #ffffff !important;
  }
}

.rounded-image {
  border-radius: 50%;
  overflow: hidden;
  [class*="icon-openFullScreen"] {
    border: solid 1px #f00;
    display: none;
  }
  &:hover {
    border: solid 1px #36786a11;
  }
}
.euiModal.product-detail-modal {
  max-inline-size: 1200px;
  max-width: 1200px;
  max-height: calc(100vh - 180px);
  min-height: 550px;
  padding: 8px 16px;
}

.euiModal.product-detail-modal-legacy {
  width: calc(100vw - 50px);
}
.stale-info {
  position: relative;
  &:after {
    border-radius: inherit;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: repeating-linear-gradient(45deg, #7e7e7e77, #c5c5c577 2px, #7e7e7e77 2px, #7e7e7e77 4px);
  }
}

.match-table .invoice-match {
  cursor: pointer;
  border: solid 1px transparent;
  &:hover {
    border: solid 1px #8198ac;
    border-collapse: collapse;
  }
}

.euiSwitch {
  .euiSwitch__body {
    background-color: #8198ac;
  }
  .euiSwitch__thumb {
    background: #fff;
    border-color: #8198ac no-repeat center;
  }
}

.contract-search > div {
  position: relative;
  & > div:first-child {
    position: sticky;
    top: 0;
    z-index: 1;
    &:before {
      z-index: 0;
      position: absolute;
      content: "";
      background: rgb(255, 255, 255);
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 95%,
        rgba(255, 255, 255, 0) 100%
      );
      left: -10px;
      right: -10px;
      top: -25px;
      bottom: -10px;
    }
  }
}

.image-preview .euiModal__closeIcon,
.pdf-preview .euiModal__closeIcon {
  position: absolute;
  top: 0px;
  z-index: 1000;
  color: #fff;
  // font-size: 20px;
  right: -30px;
  top: 17px;
}

.order-quote-rationale-modal {
  max-inline-size: 90vw !important;
  max-width: 90vw;
}

.euiOverlayMask:has(.modal-confirm) {
  z-index: 9999;
}

.subtle-table {
  tr:first-child td {
    border-top: none !important;
  }
  &.empty td {
    border-block: none;
  }
  min-height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  table {
    background-color: transparent;
  }
  thead {
    display: none;
  }
}

.euiPanel.subtle-panel {
  // border: solid 1px #0a755e99;
  background-color: #bed6d777;
  box-shadow: none;
  // box-shadow: 0 0.9px 4px rgba(0, 0, 0, 0.08), 0 2.6px 8px rgba(0, 0, 0, 0.06),
  //   0 5.7px 12px rgba(0, 0, 0, 0.05), 0 15px 15px rgba(0, 0, 0, 0.04);
}
// .grouped-order-lines-table {
//   tr.grouped td:not([data-order-id]) {
//     border-block: none;
//   }
//   tr.grouped-first td:not([data-order-id]) {
//     border-top: solid 1px #e4eae6;
//   }
//   tr.grouped-last td:not([data-order-id]) {
//     border-bottom: solid 1px #e4eae6;
//   }
// }

// .grouped-keys-table {
//   tr.grouped td:not([data-group-key]) {
//     border-block: none;
//   }
//   tr.grouped-first td:not([data-group-key]) {
//     border-top: solid 1px #e4eae6;
//   }
//   tr.grouped-last td:not([data-group-key]) {
//     border-bottom: solid 1px #e4eae6;
//   }
// }

.subtle-editing {
  .euiFormRow .euiFormControlLayout .euiFormControlLayout__childrenWrapper > *,
  .euiFormRow .euiFormControlLayout.euiFormControlLayout--group,
  .react-datepicker__input-container > input {
    box-shadow: none;
    background-color: transparent !important;
    &:hover,
    &:focus {
      background-color: #fff !important;
    }
  }

  textarea {
    font-size: 13px;
  }
  .euiFormRow .euiFormControlLayout .euiFormControlLayout__childrenWrapper > *:disabled {
    color: #343741;
    -webkit-text-fill-color: #343741;
  }
  .react-datepicker__input-container > *:disabled {
    color: #343741;
    -webkit-text-fill-color: #343741;
  }

  .euiFormControlLayout.euiFormControlLayout--group {
    background-color: #fbfcfd !important;
    .euiButtonEmpty__content {
      gap: 0;
    }
  }

  select {
    font-size: 13px;
  }

  .disabled-as-label .euiFormControlLayout .euiFormControlLayout__childrenWrapper > select:disabled {
    background-color: transparent !important;
    font-weight: 600;
  }

  *::placeholder {
    color: #c2c2c2 !important;
    font-weight: 100;
    font-size: 0.75rem;
  }
}

.order-changed-info {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  margin: auto;
}

.order-changed-info.visible {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.euiSuperSelectControl--compressed {
  line-height: inherit;
}

.euiCheckbox .euiCheckbox__input:checked + .euiCheckbox__square {
  background-color: #0a755e;
  border-color: #0a755e;
}

.euiCheckbox .euiCheckbox__input:not(:checked) + .euiCheckbox__square {
  border-color: #d2dae6;
}

.euiCheckbox .euiCheckbox__input:not(:checked) ~ .euiCheckbox__label {
  color: #7e7e7e;
}

.euiFormRow.info-appended .euiFormRow__labelWrapper {
  position: relative;
  height: 0px;
  margin: 0px;
  padding: 0px;
  overflow: visible;

  & > * {
    position: absolute;
    top: -17.5px;
    right: 0px;
  }
}

[class*="euiHorizontalRule"].rule-strong {
  background-color: #b2b2b2;
  height: 0.5px;
}
.modal-with-alternative-actions {
  .euiModalBody {
    position: relative;
    overflow: visible;
    .modal-alternative-actions {
      position: fixed;
    }
  }
}

.guttersize-xxl {
  // background-color: #f00;
  gap: 54px !important;
  &.with-products {
    gap: 45px !important;
  }
}

textarea.needs-text-area::placeholder {
  font-size: 14px;
  color: #7e7e7e !important;
}

.needs-combo-box {
  min-width: 280px;
  width: 100%;
  max-width: 100%;
  input::placeholder {
    font-size: 14px;
    color: #7e7e7e !important;
  }
  .euiBadge__text {
    font-size: 14px;
    color: #343741 !important;
    font-weight: 400;
  }
}

.euiGlobalToastList {
  pointer-events: none;
  & > * {
    pointer-events: initial;
  }
}

.scanner-form {
  max-width: 1200px;
}
.code-corrected-padding-small .euiCodeBlock__line {
  padding-top: 4px;
}
